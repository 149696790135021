







import Vue from 'vue'
import { mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import Search from '~/components/car/classifieds/search/Search.vue'
import SearchShimmer from '~/components/car/classifieds/search/shimmers/SearchShimmer.vue'
import SearchService from '~/services/search/SearchService'
import RouteGuardService from '~/services/RouteGuardService'

export default Vue.extend({
  name: 'BaseSearchPage',
  components: { Search, SearchShimmer },
  middleware({ $dep, route, redirect }) {
    if (
      (route.name?.startsWith('__classifieds_search_my_classifieds') ||
        route.name?.startsWith('__classifieds_search_my_classifieds_seo')) &&
      !$dep(RouteGuardService).userIsLoggedIn()
    ) {
      redirect(`/login?gotonext=${route.path}`)
    }
  },
  // TODO: investigate if this causes performance issues
  watchQuery(newQuery, oldQuery) {
    delete newQuery.sp
    delete oldQuery.sp
    return JSON.stringify(newQuery) !== JSON.stringify(oldQuery)
  },
  async asyncData({ route, error, $dep }) {
    const searchService = $dep(SearchService)
    await searchService.createSearch({
      route,
      errorFn: error,
      mapSearch: false
    })
  },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      searchId: state => state.searchId
    })
  },
  head() {
    const searchService = this.$dep(SearchService)
    return searchService.createSearchHead()
  }
})
